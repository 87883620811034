import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button, Input, Label, Row, Col, Spinner } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import './Header.css';
import { FaKey, FaRegUser, FaRegUserCircle, FaSignOutAlt, FaUser, FaUserAlt, FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ApiComunicator } from '../../Helpers/ApiComunicator';
import { AuthContext } from '../../App';
import { SelectSucursales } from './SelectSucursal';
import { SelectFiliales } from './SelectFilial';

export const PanelUsuario = ({ }) => {
    const [dropdownUserOpen, setDropdownUserOpen] = useState(false);
    const [dropdownVerComoOpen, setDropdownVerComoOpen] = useState(false);
    const [cuit, setCuit] = useState(null)
    const [idSucursal, setIdSucursal] = useState(null)
    const [sucursales, setSucursales] = useState([])
    const [mensaje, setMensaje] = useState("")
    const [perfiles, setPerfiles] = useState(null)
    const [loading, setLoading] = useState(false)

    const { setAutenticado } = useContext(AuthContext)

    const toggleUser = () => setDropdownUserOpen((prevState) => !prevState);
    const toggleVerComo = () => setDropdownVerComoOpen((prevState) => !prevState);

    const direction = "down";

    const navigate = useNavigate();

    const idPerfil = localStorage.getItem("idPerfil")

    const logout = async () => {
        try {
            await ApiComunicator('/auth/logout', 'GET')
            setAutenticado(false)
            navigate('/login', { replace: true })
            return;
        }
        catch (e) {

        }
    }
    const navegar = async () => {
        try {
            navigate('/cambiarPasswordMenu', { replace: true })
        } catch (error) {
            console.log(error)
        }
    }

    const getSucursalesByCuit = () => {
        ApiComunicator(`/sucursales/getSucursalesByCuit?cuit=${cuit}`, 'GET')
            .then((r) => {
                setSucursales(r)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const getPerfilUsuario = async () => {
        await ApiComunicator(`/permisos/getPerfilesDelUsuario`, 'GET')
            .then((r) => {
                setPerfiles(r)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getPerfilUsuario()
    }, [])

    const handleCuit = (e) => {
        setCuit(e.target.value)
    }
    const handleSucursal = (e) => {
        setIdSucursal(e.target.value)
    }

    useEffect(() => {
        if (cuit?.length > 10) {
            getSucursalesByCuit()
        }
    }, [cuit])

    const verComoEmpresa = () => {
        setLoading(true)
        try {
            ApiComunicator('/auth/loginAs', "POST", { idSucursal: idSucursal, codigoPerfil: "P_EMP" })
                .then((res) => {
                    setMensaje("Ingresando como empresa")
                    localStorage.setItem('razonSocial', sucursales.find(suc => suc.idSucursal == idSucursal).razonSocial);
                    localStorage.setItem('cuit', sucursales.find(suc => suc.idSucursal == idSucursal).cuit);
                    localStorage.setItem('seccional', sucursales.find(suc => suc.idSucursal == idSucursal).seccional);
                    localStorage.setItem('filial', sucursales.find(suc => suc.idSucursal == idSucursal).filial);
                    localStorage.setItem('idEstadoSucursal', sucursales.find(suc => suc.idSucursal == idSucursal).idEstado);
                    localStorage.setItem('codigoPerfil', "P_EMP");
                    localStorage.setItem('idPerfil', 2);
                    if (res.logedAsAdmin != null){
                        localStorage.setItem('logedAsAdmin',true) 
                    }
                    window.location = "/home";
                }).catch((error) => {
                    setMensaje('Error al ingresar como empresa.')
                });
        }
        catch (error) {
            setMensaje('Error al ingresar como empresa.')
        }
        finally {
            setLoading(false)
        }

    }

    const volverASerAdmin = () => {
        setLoading(true)
        try {
            ApiComunicator('/auth/loginAs', "POST", { codigoPerfil: "P_ADMIN" })
                .then((res) => {
                    setMensaje("Ingresando como Admin")
                    localStorage.removeItem('razonSocial');
                    localStorage.removeItem('cuit');
                    localStorage.removeItem('seccional');
                    localStorage.removeItem('filial');
                    localStorage.removeItem('idEstadoSucursal');
                    localStorage.setItem('codigoPerfil', "P_ADMIN");
                    localStorage.setItem('idPerfil', 1);
                    window.location = "/home";
                    //let us = res[0]
                    //us.role = localStorage.getItem("role")
                    //setUsuario(us)
                }).catch((error) => {
                    setMensaje('Error al volver al perfil admin. Error: ' + error)
                });
        }
        catch (error) {
            setMensaje('Error al volver al perfil admin.')
        }
        finally {
            setLoading(false)
        }

    }

    const volverASerSeccional = () => {
        setLoading(true)
        try {
            ApiComunicator('/auth/loginAs', "POST", { codigoPerfil: "P_SECC" })
                .then((res) => {
                    setMensaje("Ingresando como Seccional")
                    localStorage.setItem('codigoPerfil', "P_SECC");
                    localStorage.setItem('idPerfil', 4);
                    localStorage.removeItem('cuit')
                    localStorage.removeItem('razonSocial')
                    localStorage.removeItem('idEstadoSucursal')
                    window.location = "/home";
                    //let us = res[0]
                    //us.role = localStorage.getItem("role")
                    //setUsuario(us)
                }).catch((error) => {
                    setMensaje('Error al volver al perfil seccional. Error: ' + error)
                });
        }
        catch (error) {
            setMensaje('Error al volver al perfil seccional.')
        }
        finally {
            setLoading(false)
        }

    }

    return (
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            {perfiles && perfiles.some(perfil => perfil?.codigoPerfil === "P_ADMIN" || perfil?.codigoPerfil === "P_SECC") && (localStorage.getItem('codigoPerfil') === "P_ADMIN" || localStorage.getItem('codigoPerfil') === "P_SECC") &&
                <div id="panel-ver-como">
                    <Dropdown isOpen={dropdownVerComoOpen} toggle={toggleVerComo} direction={direction}>
                        <DropdownToggle id="boton-dropdown-ver-como-empresa">
                            <div>
                                Ver como Empresa
                            </div>
                        </DropdownToggle>
                        <DropdownMenu style={{ minWidth: '500px' }}>
                            <DropdownItem header>
                                <h6><b>Ver como Empresa</b></h6>
                                <Row>
                                    <Col md={4} className="mb-3">
                                        <Label for="cuit" size="sm"><b>CUIT</b></Label>
                                        <Input size="sm" id="cuit" name="cuit" type="number" value={cuit}
                                            onChange={handleCuit} />
                                    </Col>
                                    <Col md={8} className="mb-3">
                                        <Label size="sm"><b>Seccional</b></Label>
                                        <Input
                                            size="sm"
                                            type="select"
                                            name="idSucursal"
                                            value={idSucursal}
                                            onChange={(e) => handleSucursal(e)}
                                        >
                                            <option value=""></option>
                                            {sucursales.map((f) => (
                                                <option key={f.idSucursal} value={f.idSucursal} style={(f.estadoEmpresa == 'Baja' || f.estadoEmpresa == 'Baja por sucesora') ? {color: 'red'} : {}}>
                                                    {(f.estadoEmpresa == 'Baja' || f.estadoEmpresa == 'Baja por sucesora') ? `${f.estadoEmpresa.toUpperCase()}: ` : ""} N° {f.numeroEmpresa} {f.sucursal} ({f.filial.toUpperCase()})
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <div style={{ textAlign: "right" }}>
                                    <Button color="primary" disabled={!idSucursal} size="md" onClick={verComoEmpresa}>Aceptar</Button>
                                </div>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            }
            {/* Esta condicion es para saber si el admin esta logueado como empresa y asi volver a su rol de admin */}
            {perfiles && perfiles.some(perfil => perfil?.codigoPerfil === "P_ADMIN") && localStorage.getItem('codigoPerfil') === "P_EMP" &&
                <div id="panel-ver-como">
                    <Button disabled={loading} style={{ backgroundColor: "#000", marginTop: 7 }} size="md" onClick={volverASerAdmin}>Volver a Perfil Admin</Button>
                </div>
            }
            {perfiles && perfiles.some(perfil => perfil?.codigoPerfil === "P_SECC") && localStorage.getItem('codigoPerfil') === "P_EMP" &&
                <div id="panel-ver-como">
                    <Button disabled={loading} style={{ backgroundColor: "#000", marginTop: 7 }} size="md" onClick={volverASerSeccional}>Volver a Perfil Seccional</Button>
                </div>
            }
            <div id="panel-usuario">
                <Dropdown isOpen={dropdownUserOpen} toggle={toggleUser} style={{ zIndex: 9999 }} direction={direction}>
                    <DropdownToggle id="boton-dropdown-usuario">
                        <div id="logo-usuario">
                            <FaRegUser />
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        {localStorage.getItem('razonSocial') && localStorage.getItem('razonSocial') !== "null" &&
                            <>
                                <DropdownItem header id="titulo-dropdown-usuario">
                                    <b>Razón social:</b>
                                    {localStorage?.getItem('razonSocial')}
                                </DropdownItem>
                                <hr className="hidden" />
                            </>}
                        {localStorage.getItem('cuit') && localStorage.getItem('cuit') !== "null" &&
                            <>
                                <DropdownItem header id="titulo-dropdown-usuario">
                                    <b>CUIT:</b>
                                    {localStorage?.getItem('cuit')}
                                </DropdownItem>
                                <hr className="hidden" />
                            </>}
                        {localStorage.getItem('seccional') && localStorage.getItem('seccional') !== "null" && localStorage.getItem('filial') !== "null" &&
                            <>
                                <DropdownItem header id="titulo-dropdown-usuario">
                                    <b>Seccional / filial:</b>
                                    {localStorage?.getItem('seccional') + " / " + localStorage.getItem('filial')}
                                </DropdownItem>
                                <hr className="hidden" />
                            </>
                        }
                        {localStorage?.getItem('apellido') != "null" && localStorage?.getItem('nombre') != "null" &&
                            <>
                                <DropdownItem header>
                                    <b>Usuario:</b>
                                    <br />
                                    {localStorage?.getItem('apellido')},&nbsp;{localStorage?.getItem('nombre')}
                                </DropdownItem>
                                <hr className="hidden" />
                            </>
                        }
                        {localStorage.getItem('razonSocial') ?
                            <div id="select-sucursal-dropdown"><SelectSucursales /></div>
                            : <div id="select-sucursal-dropdown"><SelectFiliales /></div>
                        }
                        {/* <hr className="hidden" /> */}
                        {localStorage.getItem('idSucursal') && localStorage.getItem('idUsuario') &&
                            <div>
                                <DropdownItem onClick={() => { navigate("/empadronamiento") }}>Actualizar datos</DropdownItem>
                                <hr className="hidden" />
                            </div>}

                        <DropdownItem onClick={navegar}><FaKey /> Cambiar contraseña</DropdownItem>
                        <DropdownItem onClick={logout}><FaSignOutAlt color="#DC3545" /> Cerrar sesión</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    )
}