export const base64ABlob = async (base64) => {
    return new Promise((resolve, reject) => {
        try {
            function base64ToUint8Array(base64) {
                let binaryString = atob(base64);
                let bytes = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                return bytes;
            }
            let new_base64 = base64;
            let mime_type = `application/pdf`;
            if (!!base64.match(',')) {
                new_base64 = base64.split(',')[1];
                mime_type = base64.split(';')[0].split(':')[1]
            }
            const uint8Array = base64ToUint8Array(new_base64);
            const blob = new Blob([uint8Array], { type: mime_type });
            resolve(blob);
        }
        catch (error) {
            reject(error)
        }
    })
}