import './Steps.css';
import { } from 'reactstrap';
import React, { useState, Children, useEffect } from 'react';

export const Steps = ({ size, activeStep, children }) => {

    children = children.filter(n => !!n).flat(1)

    const childrenArray = Children.toArray(children);

    const [currentStep, setCurrentStep] = useState(() => activeStep !== undefined ? childrenArray[activeStep] : childrenArray[0])

    useEffect(() => {
        setCurrentStep(childrenArray[activeStep])
        if (childrenArray[activeStep].props.hasOwnProperty('onActive')) { childrenArray[activeStep].props.onActive() }
    }, [activeStep])

    let wrapperClass = "step-wrapper"
    let navClass = "steps-nav"
    let numClass = "num"

    if (size == "sm") {
        wrapperClass += " sm"
        numClass += " sm"
        navClass += " sm"
    }
    const StepsArray = () => {

        const len = Children.count(children);
        const active_child = childrenArray.findIndex(n => n?.key == currentStep?.key);


        return childrenArray.map(
            (n, index) =>
                <>
                    {(index < len && index > 0) &&
                        <div className={index > active_child ? "separador" : "separador active"} />
                    }
                    <div className={wrapperClass}>
                        <button className={index > active_child ? numClass : index < active_child ? `${numClass} done` : `${numClass} active`}
                            type="button"
                            onClick={() => setCurrentStep(n)}
                            disabled
                        >
                            {n?.props?.icon ? n.props.icon : index + 1}
                        </button>
                        {<div className={index == active_child ? "step-label active" : "step-label"}>{n?.props.label}</div>}
                    </div>
                </>
        );

    };

    return (
        <div className="steps">
            <div className={navClass}><StepsArray /></div>
            {children[activeStep]}
        </div>)
}

/**
 * 
 * @param {function} onActive Función que se ejecuta al renderizar el componente, es decir, cuando se setea como paso actual
 * @param {string} label Texto para mostrar debajo del indicador de pasos
 * @param {string} icon Ícono del paso
 */
export const Step = ({ onActive, label, icon, children }) => {

    return (
        <div className="step">
            {children}
        </div>)
}