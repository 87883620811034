import react, { useState, useEffect } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import "./Pagination.css";

/**
* @param {function} onSelection  
* @param {number} pages
* @param {number} currentPage  
*/

export const CustomPagination = props => {
    const [current, setCurrent] = useState(props.currentPage?props.currentPage:1)

    let pages = [...Array(props?.pages).keys(), props.pages]
    pages.shift()

    function onSelection(page) {
        if (!pages.includes(page) || page == current) { return };
        setCurrent(page)
        if (props.onSelection) {
            props.onSelection(page);
        }
    }

    console.log("COMPONENTE PAGINATION:currentPage",props.currentPage)
    const PagesToRender = _ => {
        let prevPages = pages.slice(Math.max(current - 4, 0), current).filter(p => pages.includes(p))
        let nextPages = current < 5 ? pages.slice(current, current + 5) : pages.slice(current, current + 2)
        nextPages = nextPages.filter(p => pages.includes(p))
        if (current < 5 && current > 0 && pages.length > 5) {
            for (let i = 0; i < current - 1; i++) {
                nextPages.pop()
            }
        }
        if (prevPages.length == 0 && nextPages.length == 0) {
            prevPages.push(1)
        }
        return [...prevPages, ...nextPages].map(btn =>
            <PaginationItem active={btn === current} className={btn === current ? "no-clickeable" : ""}>
                <PaginationLink
                    onClick={_ => onSelection(btn)}
                >
                    {btn}
                </PaginationLink>
            </PaginationItem>
        )
    }

    useEffect(()=>{
        setCurrent(props.currentPage)
    },[props.currentPage])

    return (
    <>{current &&
        <Pagination className="custom-pagination">
        <PaginationItem>
            <PaginationLink first
                onClick={_ => onSelection(1)} />
        </PaginationItem>
        <PaginationItem>
            <PaginationLink previous
                onClick={_ => onSelection(current - 1)}
            />
        </PaginationItem>
        <PagesToRender />
        <PaginationItem>
            <PaginationLink next
                onClick={_ => onSelection(current + 1)}
            />
        </PaginationItem>
        <PaginationItem>
            <PaginationLink last
                onClick={_ => onSelection(pages?.at(-1))}
            />
        </PaginationItem>
        </Pagination>}
    </>
    )
} 