import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Container } from "reactstrap"
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ApiComunicator } from "../../Helpers/ApiComunicator";
import currency from "currency.js";
import { CustomTable } from "../CustomTable/CustomTable";
import { FaTrash } from "react-icons/fa";

export const ModalImputacionDetalle = ({ idPercibido, setIdPercibido, onChange = () => { } }) => {

    const [detalleImputacion, setDetalleImputacion] = useState([])
    const [modalAccion, setModalAccion] = useState({
        accion: "",
        idRelacion: null
    })
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (idPercibido) {
            setLoading(true)
            getDetalle()
        }
        else {
            setDetalleImputacion([])
        }
    }, [idPercibido])

    const getDetalle = () => {
        ApiComunicator(`/pagos/getDetalleMultiplesBoletas?idPercibido=${idPercibido}`, "GET")
            .then((r) => {
                setDetalleImputacion(r);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const confirmarAccion = async () => {
        try {

            setLoading(true)
            switch (modalAccion.accion) {
                case "rechazarImputacion":
                    await ApiComunicator(`/administracion/rechazarImputacionEmpresa`, "POST", { idRelacion: modalAccion.idRelacion })
                    break;
                case "confirmarImputacion":
                    await ApiComunicator(`/administracion/aprobarImputacionEmpresa`, "POST", { idRelacion: modalAccion.idRelacion })
                    break;
            }

            onChange()

        }
        catch (err) { }
        finally {
            getDetalle()
            setModalAccion({
                accion: "",
                idPercibido: null,
            })
        }
    }

    return <div>
        <Modal
            size="xl"
            isOpen={idPercibido != null}
            toggle={() => setIdPercibido(null)}
        >
            <ModalHeader>
                <b>Detalle de Imputación Boletas </b>
            </ModalHeader>
            <ModalBody>
                {detalleImputacion.length > 0 ? (
                    <CustomTable responsive size="sm" style={{ fontSize: "85%" }}>
                        <thead className="sticky-top">
                            <tr>
                                <th>Seccional</th>
                                <th>Frente</th>
                                <th>Periodo</th>
                                <th>Nro de Boleta</th>
                                <th>Cuota</th>
                                <th style={{ textAlign: "right" }}>Importe Boleta</th>
                                <th style={{ textAlign: "right" }}>Intereses Boleta</th>
                                <th style={{ textAlign: "right" }}>Total Boleta</th>
                                <th style={{ textAlign: "right" }}>Imputado importe</th>
                                <th style={{ textAlign: "right" }}>Imputado intereses</th>
                                <th style={{ textAlign: "right" }}>Total imputado</th>

                                <th style={{ textAlign: "right" }}>Estado imputacion</th>

                            </tr>
                        </thead>
                        <tbody>
                            {detalleImputacion.map((p, index) => (
                                <tr key={index}>
                                    <td style={{ fontSize: "80%" }}>{p.codSucursal} - {p.sucursal} / N° {p.numeroSeccional} - {p.seccional?.toUpperCase()}</td>
                                    <td>{p.frente} {(p?.esBoletaInteres == 1) ? `(Bol. Interés)` : ""} </td>
                                    <td>{moment(p.periodo, "YYYYMM").format("MM/YYYY")}</td>
                                    <td>{p.numero ? p.numero : "-"}</td>
                                    <td>{p.nroCuota ? `${p.nroCuota}/${p.cantCuotas}` : "-"}</td>
                                    <td style={{ textAlign: "right" }}>{currency(p.importeBoleta).format({ decimal: ",", separator: "." })}</td>
                                    <td style={{ textAlign: "right" }}>{currency(p.interesesBoleta).format({ decimal: ",", separator: "." })}</td>
                                    <td style={{ textAlign: "right" }}>{currency(p.importeBoleta).add(p.interesesBoleta).format({ decimal: ",", separator: "." })}</td>
                                    <td style={{ textAlign: "right" }}>{currency(p.imputadoImporte).format({ decimal: ",", separator: "." })}</td>
                                    <td style={{ textAlign: "right" }}>{currency(p.imputadoIntereses).format({ decimal: ",", separator: "." })}</td>
                                    <td style={{ textAlign: "right" }}>{currency(p.imputadoImporte).add(p.imputadoIntereses).format({ decimal: ",", separator: "." })}</td>
                                    {
                                        (localStorage.getItem("codigoPerfil") == "P_ADMIN" || localStorage.getItem("codigoPerfil") == "P_SECC")
                                        &&
                                        (p.imputado == 0
                                            ?
                                            <td align="center" style={{ whiteSpace: "nowrap" }}>
                                                <Button
                                                    size="sm"
                                                    style={{ display: "inline-block", padding: "3px", margin: "2px", fontSize: "90%" }}
                                                    color="danger"
                                                    onClick={() => {
                                                        setModalAccion({
                                                            accion: "rechazarImputacion",
                                                            idRelacion: p.idRelacion
                                                        })
                                                    }}
                                                >
                                                    Rechazar
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    style={{ display: "inline-block", padding: "3px", margin: "2px", fontSize: "90%" }}
                                                    color="success"
                                                    onClick={() => {
                                                        setModalAccion({
                                                            accion: "confirmarImputacion",
                                                            idRelacion: p.idRelacion
                                                        })
                                                    }}
                                                >
                                                    Aprobar
                                                </Button>

                                            </td>
                                            :
                                            <td align="center">APROBADO</td>
                                        )
                                    }
                                    {
                                        (localStorage.getItem("codigoPerfil") == "P_EMP")
                                        &&
                                        (p.imputado == 0
                                            ?
                                            <td align="center" style={{ whiteSpace: "nowrap" }}>
                                                <p style={{ display: "inline-block", padding: "3px", margin: "2px", fontSize: "90%" }}>PENDIENTE</p>
                                                <Button
                                                    size="sm"
                                                    style={{ display: "inline-block", padding: "3px", margin: "2px", fontSize: "90%" }}
                                                    color="danger"
                                                    onClick={() => {
                                                        setModalAccion({
                                                            accion: "rechazarImputacion",
                                                            idRelacion: p.idRelacion
                                                        })
                                                    }}
                                                >
                                                    <FaTrash></FaTrash>
                                                </Button>
                                            </td>
                                            :
                                            <td align="center">APROBADO</td>
                                        )
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </CustomTable>
                ) : (
                    <p>No hay datos disponibles.</p>
                )}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Button
                    color="primary"
                    onClick={() => setIdPercibido(null)}
                >
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>


        <Modal isOpen={modalAccion.accion != ""}>
            <ModalHeader>
                Confirmacion
            </ModalHeader>
            <ModalBody>
                {modalAccion.accion == "rechazarImputacion" &&
                    ((localStorage.getItem("codigoPerfil") == "P_ADMIN" || localStorage.getItem("codigoPerfil") == "P_SECC")
                        ?
                        "Esta seguro que desea RECHAZAR la solicitud de imputacion de la empresa"
                        :
                        "Esta seguro que desea ELIMINAR la asociacion del pago con esta boleta"
                    )
                }
                {modalAccion.accion == "confirmarImputacion" && "Usted va a APROBAR la solicitud de imputacion de la empresa"}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Container fluid>
                    <Row>
                        <Col>
                            <Button
                                color="danger"
                                onClick={() => setModalAccion({
                                    accion: "",
                                    idRelacion: null
                                })}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col align="right">
                            <Button
                                color="primary"
                                onClick={() => confirmarAccion()}
                            >
                                Confirmar
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </ModalFooter>
        </Modal>

    </div >

}