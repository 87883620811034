import { Input, FormGroup, Label } from 'reactstrap';
import './Header.css';
import { ApiComunicator } from "../../Helpers/ApiComunicator";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../Spinner/Spinner';

export const SelectFiliales = () => {
    const [filiales, setFiliales] = useState()
    const [idFilial, setIdFilial] = useState(localStorage?.getItem('idFilial'))
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    async function traerFiliales() {
        try {
            const data = await ApiComunicator(`/filiales/getFilialesByUsuario`, "GET")
            setFiliales(data)
        }
        catch (error) {
            // throw error
        }
    }

    async function handleChange(e) {
        e.preventDefault();
        try {
            // setIdFilial(e.target.value)
            setIdFilial(e.target.value)
            setLoading(true)

            const { data } = await ApiComunicator(`/auth/changeToken`, "POST", { ...localStorage, idFilial: parseInt(e.target.value) })
            //Limpio el Local Storage
            localStorage.clear();
            //Guardo los datos básicos del usuario en el Local Storage
            // localStorage.setItem('filial', filiales.find(f => f.idFilial == e.target.value).filial);

            Object.entries(data).forEach(([key, value]) => {
                localStorage.setItem(key, value);
            });
            localStorage.setItem('seccional', filiales.find(f => f.idFilial == e.target.value).seccional);
            localStorage.setItem("filial", filiales.find(f => f.idFilial == e.target.value).filial)
            navigate(0, { replace: true })

        }
        catch (error) {
            /* throw error */
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        traerFiliales();
    }, [])

    return (
        <FormGroup id="sucursal-header">
            {loading && <Spinner />}
            <span id="sucursal-header-label"><b>Seccional: </b></span>
            <Label id="sucursal-header-label-mobile"><b>Seccional</b></Label>
            <Input
                size="sm"
                type="select"
                id="idFilial"
                onChange={handleChange}
                value={idFilial}
                required
            >
                <option disabled value="">Seleccione una seccional</option>
                {filiales?.length > 0 && filiales?.sort((a, b) => {
                if ( a.sucursal < b.sucursal && a.filial ){
                  return -1;
                }
                if ( a.sucursal > b.sucursal && a.filial ){
                  return 1;
                }
                return 0;
              }).map(n => <option key={n.idFilial} value={n.idFilial}>{n.filial}</option>)}
            </Input>
        </FormGroup>
    )
}