import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Container } from "reactstrap"
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "../Spinner/Spinner";
import { ApiComunicator } from "../../Helpers/ApiComunicator";
import { base64ABlob } from "../../Helpers/base64-a-blob";
import { FaPrint } from "react-icons/fa";
/**
 * imprime boletas emitidas y avisa si estan vencidas
 */

export const Reimprimir = ({ fechaVencimiento, idDevengado, type = "button" }, children) => {

    const [modalError, setModalError] = useState(null);
    const [modalVencida, setModalVencida] = useState(null);
    const [loading, setLoading] = useState(false);

    async function handleReimprimir(fechaVencimiento, idDevengado, e) {
        e.preventDefault();
        if (moment(fechaVencimiento).endOf('day').isBefore(moment().startOf('day'))) {
            setModalVencida(idDevengado)
        }
        else {
            imprimirBoleta(idDevengado)
        }

    }

    async function imprimirBoleta(idDevengado) {
        try {
            setModalVencida(null)
            setLoading(true)
            const { base64 } = await ApiComunicator(`/boletas/getBoleta?idDevengado=${idDevengado}&type=pdfBase64`, "GET")
            const blob = await base64ABlob(base64);
            let url = URL.createObjectURL(blob);
            if (document.getElementById("iframe-reimprimir")) {
                document.body.removeChild(document.getElementById("iframe-reimprimir"))
            }
            let iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.id = "iframe-reimprimir"
            iframe.style.display = 'none';
            iframe.src = url;
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1);
            };
        }
        catch (error) {
            setModalError("Ocurrio un error obteniendo la boleta")
        }
        finally {
            setLoading(false)
        }
    }


    return <div>
        {loading && <Spinner />}

        {type == "button" &&
            <Button color="primary" type="button" onClick={(e) => handleReimprimir(fechaVencimiento, idDevengado, e)}>
                <FaPrint />  Imprimir
            </Button>
        }
        {type == "link" &&
            <u
                className='text-primary'
                style={{ cursor: "pointer" }}
                onClick={(e) => { handleReimprimir(fechaVencimiento, idDevengado, e) }}
            >  Imprimir</u>
        }
        <Modal isOpen={modalVencida != null}>
            <ModalHeader>BOLETA VENCIDA</ModalHeader>
            <ModalBody>
                ATENCION: La boleta que va a imprimir esta vencida y debe ser emitida nuevamente para realizar su pago.
            </ModalBody>
            <ModalFooter>
                <Container fluid>
                    <Row>
                        <Col md={6}>
                            <Button onClick={() => setModalVencida(null)}>
                                Volver
                            </Button>
                        </Col>
                        <Col align="right" md={6}>
                            <Button color="primary" onClick={() => imprimirBoleta(modalVencida)}>
                                Imprimir
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </ModalFooter>
        </Modal>
        <Modal isOpen={modalError != null}>
            <ModalHeader>ERROR</ModalHeader>
            <ModalBody>
               {modalError}
            </ModalBody>
            <ModalFooter>
                <Container fluid>
                    <Row>
                        <Col md={6}>
                        </Col>
                        <Col align="right" md={6}>
                            <Button onClick={() => setModalError(null)}>
                                Volver
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </ModalFooter>
        </Modal>
    </div >

}