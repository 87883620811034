import { Card, Nav, NavItem, NavLink, TabContent, Navbar, NavbarToggler, NavbarBrand, Collapse } from 'reactstrap';
import './Layout.css';
import { Outlet, useLocation, matchRoutes, useNavigate } from 'react-router-dom';
import { Header } from '..';
import React, { useState, useEffect } from 'react';
import { routes } from '../../routes';
import { Spinner } from '../../Components';
import { ApiComunicator } from '../../Helpers/ApiComunicator';

//Hook para levantar el ancho de la pantalla
const useWidth = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowSize(window.innerWidth);
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    return windowSize;
};

export const Layout = (props) => {

    const location = useLocation();
    const pathPatterns = Object.keys(routes).map(r => { return { path: routes[r].pathPattern } })

    function isActive(tab) {
        const ruta = location.pathname.split('/')[1];
        return ruta == tab
    }

    const [allowedPatterns, setAllowedPatterns] = useState([])
    const [currentPattern, setCurrentPattern] = useState([])
    const [rutasTab, setRutasTab] = useState([]);
    const [loading, setLoading] = useState(true);
    const [collapsed, setCollapsed] = useState(true);
    const [vistaActual, setVistaActiva] = useState();
    const width = useWidth();

    const toggleNavbar = () => setCollapsed(!collapsed);

    useEffect(() => {
        const [{ route }] = matchRoutes(pathPatterns, location)
        setCurrentPattern(route.path)
        if (location.pathname) {

            rutasTab.map(r => {
                if (isActive(r.path())) {
                    setVistaActiva(<>{r.icon} {r.name}</>)
                }
            })
        }
    }, [location])

    useEffect(() => {
        ApiComunicator("/permisos/getRutas", "GET")
            .then((res) => {
                let allowedRoutes = res.map(r => { return r.ruta })
                setAllowedPatterns(res.map(r => { return r.pattern }))
                let aux = Object.keys(routes).map(r => { return { ...routes[r], id: r } }).filter(r => r.parent == null && r.icon && allowedRoutes.includes(r.id))
                setRutasTab(aux)
                aux.map(r => {
                    if (isActive(r.path())) {
                        setVistaActiva(<>{r.icon} {r.name}</>)
                    }
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (rutasTab.length > 0) {
            setLoading(false)
        }
    }, [rutasTab])

    if (loading) {
        return <Spinner />
    }

    if (width > 600) {

        return (<>
            <Header />
            <div id="tabs-layout">
                <Nav tabs>
                    {rutasTab.map(r =>
                        <NavItem>
                            <NavLink active={isActive(r.path())} href={'/' + r.path()}>
                                {r.icon} {r.name}
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
                <TabContent className="tab-content">
                    <Card className="main-card">
                        {allowedPatterns.includes(currentPattern) &&
                            <Outlet />
                        }
                    </Card>
                </TabContent>
            </div>
        </>
        )
    }
    return (<>
        <Header />
        <div id="navbar-layout">
            <Navbar color="faded" light>
                <NavbarBrand href={location.pathname.split('/').at(-1)}>
                    {vistaActual}
                </NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} />
                <Collapse isOpen={!collapsed} navbar>
                    <Nav navbar>
                        {rutasTab.map(r =>
                            <NavItem hidden={isActive(r.path())}>
                                <NavLink href={'/' + r.path()}>
                                    {r.icon} {r.name}
                                </NavLink>
                            </NavItem>
                        )}

                    </Nav>
                </Collapse>
            </Navbar>
            <TabContent className="tab-content">
                <Card className="main-card">
                    {allowedPatterns.includes(currentPattern) &&
                        <Outlet />
                    }
                </Card>
            </TabContent>
        </div>
    </>
    )
}