import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Container, Label, Input, NavItem, NavLink, TabContent, TabPane, Nav, InputGroup } from "reactstrap"
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ApiComunicator } from "../../Helpers/ApiComunicator";
import currency from "currency.js";
import { Spinner } from "../Spinner/Spinner";
import { CustomTable } from "../CustomTable/CustomTable";
import { ModalMensaje } from "../ModalMensaje/ModalMensaje";

export const ModalImputacion = ({
    isOpen = false,
    idPercibido,    //Si es imputacion de saldo a favor de un percibido, campo cuit deshabilitado
    idLinea,        //Si es imputacion de una linea de archivo, habilito campo cuit
    callback
}) => {

    const [tipoImputacion, setTipoImputacion] = useState("")
    //extractoBancario
    //txtAfip
    //saldoAFavor

    const [loading, setLoading] = useState(true)
    const [modalConfirmacion, setModalConfirmacion] = useState(false)
    const [modalMensaje, setModalMensaje] = useState({ isOpen: false, mensaje: "" })
    const [frentes, setFrentes] = useState(false)
    const [boletasPendientes, setBoletasPendientes] = useState([])
    const [boletasImputadas, setBoletasImputadas] = useState([])
    const [sucursales, setSucursales] = useState([])
    const [tab, setTab] = useState("pendientes")
    const [modalForm, setModalForm] = useState({
        cuit: null,
        idSucursal: null,
        fechaAsiento: null,
        idFrente: "",
        periodo: "",
        periodoMM: "",
        periodoYYYY: "",
        importeBoleta: "",
        interesesBoleta: "",
        cantidadPersonas: "",
        totalRemuneraciones: "",
        nroCuota: "",
        cuotas: "",
        importeAporteConvenio: "",
        monto: "",
        cantidadPersonas: ""
    })

    useEffect(() => {
        getFrentes()
    }, [])

    useEffect(() => {
        if (!isOpen) {
            setBoletasImputadas([])
            setSucursales([])
            setBoletasPendientes([])
            setModalForm({
                cuit: null,
                idSucursal: null,
                fechaAsiento: null,
                idFrente: "",
                periodo: "",
                periodoMM: "",
                periodoYYYY: "",
                importeSinBoleta: "",
                interesesSinBoleta: "",
                cantidadPersonas: "",
                totalRemuneraciones: "",
                nroCuota: "",
                cuotas: "",
                importeAporteConvenio: "",
                totalRemuneraciones: "",
                monto: "",
                cuenta: "",
                cantidadPersonas: ""
            })
            setTab("pendientes")
            setLoading(true)
        }
        else {
            initForm()
        }
    }, [isOpen])


    const initForm = async () => {

        try {
            //Traigo la info segun si es linea de archivo o percibido

            let modalFormAux = JSON.parse(JSON.stringify(modalForm))

            if (idLinea) {
                await ApiComunicator(`/administracion/getInfoLinea?idLinea=${idLinea}`, "GET")
                    .then((r) => {
                        setTipoImputacion(r.tipo)

                        modalFormAux.fechaAsiento = r.fecha
                        modalFormAux.lineNumber = r.lineNumber
                        modalFormAux.totalDepositado = r.totalDepositado
                        modalFormAux.cuitOCuil = r.cuitOCuil
                        modalFormAux.cuit = r.cuitOCuil
                        modalFormAux.afip_capital = r.afip_capital
                        modalFormAux.afip_interes = r.afip_interes
                        modalFormAux.cuenta = r.cuenta
                    })

                if (modalFormAux.cuitOCuil?.toString().length > 10) {
                    await ApiComunicator(`/sucursales/getSucursalesByCuit?cuit=${modalFormAux.cuitOCuil.toString()}`, 'GET')
                        .then((r) => {
                            setSucursales(r)
                            if (r.length == 1) modalFormAux.idSucursal = r[0].idSucursal.toString()
                        })

                } else {
                    setSucursales([])
                }

            }
            if (idPercibido) {
                await ApiComunicator(`/administracion/getInfoPercibido?idPercibido=${idPercibido}`, "GET")
                    .then((r) => {
                        setTipoImputacion("saldoAFavor")

                        modalFormAux.saldo = r.saldoAFavorPendiente
                        modalFormAux.idSucursal = r.idSucursal.toString()
                    })

                await ApiComunicator(`/sucursales/getSucursalById`, 'POST', { idSucursal: modalFormAux.idSucursal })
                    .then((r) => {
                        setSucursales([r.data])
                        modalFormAux.cuitOCuil = r.data.cuit
                        modalFormAux.cuit = r.data.cuit
                    })

            }

            setModalForm(modalFormAux)
        } catch (err) {
            setModalMensaje({ isOpen: true, mensaje: "Ocurrio un error al consultar la informacion." })
            callback()
        } finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        if (modalForm.idSucursal != "" && modalForm.idSucursal != null) {
            ApiComunicator(`/boletas/getBoletasPendientesDePagoSucursal`, "POST", { idSucursal: modalForm.idSucursal })
                .then((r) => {
                    setBoletasPendientes(r.boletas)
                })
                .catch((err) => { })
        }

    }, [modalForm.idSucursal])

    const handleChange = async (e) => {

        let formAux = { ...modalForm }

        let val = e.target.value
        let name = e.target.name

        if (name == "cuit") {
            formAux.idSucursal = ""
            if (val.length > 10) {
                await ApiComunicator(`/sucursales/getSucursalesByCuit?cuit=${val}`, 'GET')
                    .then((r) => {
                        setSucursales(r)
                        if (r.length == 1) formAux.idSucursal = r[0].idSucursal.toString()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                setSucursales([])
            }
        }

        formAux[name] = val

        setModalForm({ ...formAux })
    }

    async function getFrentes() {
        try {
            const data = await ApiComunicator('/boletas/getFrentes', 'GET')
            setFrentes(data)
        }
        catch (error) {

        }
    }

    const imputar = async (b, tipo) => {

        //CHEQUEO QUE LA SUMA DE LOS MONTOS NO SEA SUPERIOR AL TOTAL DEPOSITADO

        let boleta = {}

        boleta.idFrente = b.idFrente
        boleta.nroCuota = b.nroCuota
        boleta.cantCuotas = b.cantCuotas
        boleta.esBoletaInteres = b.esBoletaInteres

        if (tipo == "pendientes") {
            if (boletasImputadas.find(bol => bol.idDevengado == b.idDevengado)) {
                setModalMensaje({ isOpen: true, mensaje: "Esta boleta ya esta imputada." })
                return
            }
            else if (tipoImputacion == "txtAfip" && boletasImputadas.length > 0) {
                //Si es de afip puedo imputar una sola
                setModalMensaje({ isOpen: true, mensaje: "No puede seleccionar mas de una boleta para imputar por afip." })
                return
            }
            else {
                boleta.idDevengado = b.idDevengado
                boleta.periodo = b.periodo

                boleta.importeBoleta = b.restaPagarImporteBoleta
                boleta.interesesBoleta = b.restaPagarInteresesBoleta
                if (tipoImputacion == "txtAfip") {
                    if (modalForm.afip_capital > 0) {
                        boleta.afip_capital = b.restaPagarImporteBoleta
                        if (currency(boleta.afip_capital).value > currency(modalForm.afip_capital).value) {
                            setModalMensaje({ isOpen: true, mensaje: "ADVERTENCIA: el capital de la boleta seleccionada supera el capital del pago." })
                        }
                    }
                    if (modalForm.afip_interes > 0) {
                        boleta.afip_interes = b.restaPagarInteresesBoleta
                        if (currency(boleta.afip_interes).value > currency(modalForm.afip_interes).value) {
                            setModalMensaje({ isOpen: true, mensaje: "ADVERTENCIA: el interes de la boleta seleccionada supera el interes del pago." })
                        }
                    }
                }
                else {
                    boleta.monto = currency(b.restaPagarImporteBoleta).add(b.restaPagarInteresesBoleta).value
                }

            }
        }

        if (tipo == "sinBoleta") {
            boleta.idDevengado = null
            boleta.periodo = (b.periodoMM != "" && b.periodoYYYY != "") ? b.periodoYYYY.padStart(4, "0") + b.periodoMM.padStart(2, "0") : "000000"
            boleta.cantidadPersonas = b.cantidadPersonas
            boleta.totalRemuneraciones = b.totalRemuneraciones
            boleta.nroCuota = b.nroCuota
            boleta.cantCuotas = b.cuotas
            boleta.importeAporteConvenio = b.importeAporteConvenio
            boleta.importeBoleta = b.importeSinBoleta
            boleta.interesesBoleta = b.interesesSinBoleta
            boleta.monto = b.monto
        }

        if (tipoImputacion != "txtAfip") {

            let saldo = 0
            if (tipoImputacion == "extractoBancario") saldo = modalForm.totalDepositado
            if (tipoImputacion == "saldoAFavor") saldo = modalForm.saldo

            if (currency(boletasImputadas.reduce((a, c) => currency(a).add(c.monto).value, 0)).add(boleta.monto).value > currency(saldo).value) {
                if (boletasImputadas.length > 0) { //Si es una sola le permito pago parcial
                    setModalMensaje({ isOpen: true, mensaje: "Las boletas seleccionadas superan el total del pago." })
                    return
                }
                else {
                    setModalMensaje({ isOpen: true, mensaje: "ADVERTENCIA: El total a pagar supera el total depositado, se realizara un pago parcial." })
                }
            }
        }

        let anio = boleta.periodo.toString().substring(0, 4)
        let mes = boleta.periodo.toString().substring(4, 6)

        if (
            !(parseInt(mes) < 13 && parseInt(mes) > 0)
            ||
            !(parseInt(anio) < 9999 && parseInt(anio) > 1999)
        ) {
            setModalMensaje({ isOpen: true, mensaje: "Periodo invalido." })
            return
        }

        if (boleta.monto <= 0) {
            setModalMensaje({ isOpen: true, mensaje: "Monto invalido." })
            return
        }
        if (!boleta.idFrente || boleta.idFrente == "") {
            setModalMensaje({ isOpen: true, mensaje: "Frente invalido." })
            return
        }

        if (tipo != "sinBoleta" && b.idDeclaracionJurada) {
            //Traigo la cantidad de personas y total de remuneraciones
            setLoading(true)
            ApiComunicator("/administracion/getPersonasDeclaracionJurada?idDeclaracionJurada=" + b.idDeclaracionJurada, "GET")
                .then((r) => {
                    boleta.cantidadPersonas = r.cantidadPersonas
                    boleta.totalRemuneraciones = r.totalRemuneraciones
                })
                .catch((err) => { })
                .finally(() => {
                    setLoading(false)
                })

        }

        if (tipo == "sinBoleta") setModalForm({
            ...modalForm,
            idFrente: "",
            periodo: "",
            periodoMM: "",
            periodoYYYY: "",
            importeSinBoleta: "",
            interesesSinBoleta: "",
            cantidadPersonas: "",
            totalRemuneraciones: "",
            nroCuota: "",
            cuotas: "",
            importeAporteConvenio: "",
            monto: "",
            cantidadPersonas: ""
        })

        setBoletasImputadas([...boletasImputadas, boleta])
    }

    const confirmarImputacion = () => {
        setModalConfirmacion(false)

        if (boletasImputadas.length == 0) {
            setModalMensaje({ isOpen: true, mensaje: "No seleccionó boletas para realizar la imputacion" })
            return
        }

        if (!modalForm.fechaAsiento && tipoImputacion != "saldoAFavor") {
            setModalMensaje({ isOpen: true, mensaje: "No seleccionó la fecha asiento" })
            return
        }

        setLoading(true)
        ApiComunicator("/administracion/confirmarImputacion", "POST", {
            idLinea: idLinea,
            idPercibido: idPercibido,
            boletas: boletasImputadas,
            tipoImputacion: tipoImputacion,
            idSucursal: modalForm.idSucursal,
            fechaAsiento: modalForm.fechaAsiento,
        })
            .then((r) => {
                callback()
            })
            .catch((err) => {

            })
            .finally(() => {
                setLoading(false)
            })
    }

    return <div>

        {(loading && isOpen) && <Spinner />}

        <Modal size="xl" isOpen={isOpen}>
            <ModalHeader>Imputar linea</ModalHeader>

            <ModalBody>
                <Container>
                    <Row>
                        {tipoImputacion == "extractoBancario" &&
                            <Col md={1}>
                                <Label size="sm">Linea</Label>
                                <Input size="sm" disabled type="text" value={modalForm.lineNumber}></Input>
                            </Col>
                        }
                        {tipoImputacion == "extractoBancario" &&
                            <Col md={2}>
                                <Label size="sm">Total depositado</Label>
                                <Input size="sm" disabled type="text" value={modalForm.totalDepositado}></Input>
                            </Col>
                        }
                        {tipoImputacion == "extractoBancario" &&
                            <Col md={2}>
                                <Label size="sm">Cuenta</Label>
                                <Input size="sm" disabled type="text" value={modalForm.cuenta}></Input>
                            </Col>
                        }
                        {tipoImputacion == "extractoBancario" &&
                            <Col>
                                <Label size="sm">Fecha Asiento</Label>
                                <Input
                                    size="sm"
                                    type="date"
                                    name="fechaAsiento"
                                    value={modalForm.fechaAsiento}
                                    onChange={handleChange}
                                />
                            </Col>
                        }
                        {tipoImputacion == "txtAfip" &&
                            <Col md={2}>
                                <Label size="sm">Depositado Capital</Label>
                                <Input size="sm" disabled type="text" value={modalForm.afip_capital ?? 0}></Input>
                            </Col>
                        }
                        {tipoImputacion == "txtAfip" &&
                            <Col md={2}>
                                <Label size="sm">Depositado Interes</Label>
                                <Input size="sm" disabled type="text" value={modalForm.afip_interes ?? 0}></Input>
                            </Col>
                        }
                        {tipoImputacion == "txtAfip" &&
                            <Col>
                                <Label size="sm">Fecha Asiento</Label>
                                <Input
                                    size="sm"
                                    type="date"
                                    name="fechaAsiento"
                                    value={modalForm.fechaAsiento}
                                    onChange={handleChange}
                                />
                            </Col>
                        }
                        {tipoImputacion == "saldoAFavor" &&
                            <Col md={2}>
                                <Label size="sm">Saldo</Label>
                                <Input size="sm" disabled type="text" value={modalForm.saldo}></Input>
                            </Col>
                        }
                        <Col md={2}>
                            <Label size="sm">CUIT</Label>
                            <Input
                                size="sm"
                                disabled={tipoImputacion == "saldoAFavor"}
                                type="text"
                                name="cuit"
                                value={modalForm.cuit}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col>
                            <Label size="sm">Seccional</Label>
                            <Input
                                size="sm"
                                disabled={tipoImputacion == "saldoAFavor"}
                                type="select"
                                name="idSucursal"
                                value={modalForm.idSucursal}
                                onChange={handleChange}
                            >
                                <option value="">{sucursales?.length > 0 && "-- SELECCIONE SECCIONAL --"}</option>
                                {sucursales.map(f =>
                                    <option style={(f.estadoEmpresa == 'Baja' || f.estadoEmpresa == 'Baja por sucesora') ? { color: 'red' } : {}} value={f.idSucursal}>
                                        {(f.estadoEmpresa == 'Baja' || f.estadoEmpresa == 'Baja por sucesora') ? `${f.estadoEmpresa.toUpperCase()}: ` : ""} {f.codSucursal} - {f.sucursal} / N°{f.codFilial} {f.filial.toUpperCase()}
                                    </option>
                                )}
                            </Input>
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col>
                            <Nav tabs >
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        active={tab == "pendientes"}
                                        onClick={() => { setTab("pendientes") }}
                                    >
                                        Boletas pendientes de pago
                                    </NavLink>
                                </NavItem>
                                {tipoImputacion == "extractoBancario" &&
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            active={tab == "sinBoleta"}
                                            onClick={() => { setTab("sinBoleta") }}
                                        >
                                            Sin boleta
                                        </NavLink>
                                    </NavItem>
                                }
                            </Nav>
                            <TabContent activeTab={tab}>
                                <TabPane tabId="pendientes" style={{ padding: "10px", width: "100%", borderBottom: "groove 1px", borderLeft: "groove 1px", borderRight: "groove 1px" }}>
                                    <div className="scrollable">
                                        <CustomTable size="sm">
                                            <thead class="sticky-top">
                                                <tr>
                                                    <th>Frente</th>
                                                    <th>Periodo</th>
                                                    <th>Cuota</th>
                                                    <th>N° Boleta</th>
                                                    <th>Importe</th>
                                                    <th>Interés</th>
                                                    <th>Total</th>
                                                    {tipoImputacion != "txtAfip" && <th>Resta pagar</th>}
                                                    {tipoImputacion == "txtAfip" && <th>Resta pagar importe</th>}
                                                    {tipoImputacion == "txtAfip" && <th>Resta pagar interes</th>}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {modalForm.idSucursal == "" ?
                                                    <tr>
                                                        <td align="center" colSpan={3}>Seleccione una seccional para ver las boletas pendientes de pago</td>
                                                    </tr>
                                                    :
                                                    (
                                                        boletasPendientes.length == 0 ?
                                                            <tr>
                                                                <td align="center" colSpan={3}>No se encontraron boletas pendientes de pago para la seccional</td>
                                                            </tr>
                                                            :
                                                            boletasPendientes.sort((a, b) => b.periodo - a.periodo).map(b =>
                                                                <tr>
                                                                    <td>{b.frente} {(b?.esBoletaInteres == 1) ? `(Bol. Interés)` : ""}</td>
                                                                    <td>{moment(b.periodo, "YYYYMM").format("MM/YYYY")}</td>
                                                                    <td>{b.nroCuota ? `${b.nroCuota}/${b.cantCuotas}` : "-"}</td>
                                                                    <td>{b.numero}</td>
                                                                    <td>{currency(b.importeBoleta).format()}</td>
                                                                    <td>{currency(b.interesesBoleta).format()}</td>
                                                                    <td>{currency(b.importeBoleta).add(b.interesesBoleta).format()}</td>

                                                                    {tipoImputacion != "txtAfip" && <td>{currency(b.restaPagarImporteBoleta).add(b.restaPagarInteresesBoleta).format()}</td>}
                                                                    {tipoImputacion == "txtAfip" && <td>{currency(b.restaPagarImporteBoleta).format()}</td>}
                                                                    {tipoImputacion == "txtAfip" && <td>{currency(b.restaPagarInteresesBoleta).format()}</td>}

                                                                    <td>
                                                                        <Button
                                                                            size="sm"
                                                                            onClick={() => imputar(b, "pendientes")}
                                                                        >
                                                                            + Imputar
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    )

                                                }
                                            </tbody>
                                        </CustomTable>
                                    </div>
                                </TabPane>
                                <TabPane tabId="sinBoleta" style={{ padding: "10px", width: "100%", borderBottom: "groove 1px", borderLeft: "groove 1px", borderRight: "groove 1px" }}>
                                    {(modalForm.idSucursal == "" || modalForm.idSucursal == null) ?
                                        "Seleccione una seccional para cargar una boleta"
                                        :
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <Label size="sm">Frente</Label>
                                                    <Input
                                                        size="sm"
                                                        type="select"
                                                        name="idFrente"
                                                        value={modalForm.idFrente}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">-- Seleccione frente --</option>
                                                        {frentes.map(f =>
                                                            <option value={f.idFrente}>{f.frente}</option>
                                                        )}
                                                    </Input>
                                                </Col>
                                                <Col>
                                                    <Label size="sm">Periodo</Label>

                                                    <InputGroup>
                                                        <Input size='sm'
                                                            id="periodoMM"
                                                            name="periodoMM"
                                                            style={{ maxWidth: "2.5em" }}
                                                            maxLength={2}
                                                            type="text"
                                                            value={modalForm.periodoMM}
                                                            onChange={handleChange}
                                                        />
                                                        &nbsp;/&nbsp;
                                                        <Input size='sm'
                                                            style={{ maxWidth: "4em" }}
                                                            id="periodoYYYY"
                                                            name="periodoYYYY"
                                                            maxLength={4}
                                                            type="text"
                                                            value={modalForm.periodoYYYY}
                                                            onChange={handleChange}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <Label size='sm'>Importe</Label>
                                                    <Input
                                                        size="sm"
                                                        type='number'
                                                        name='importeSinBoleta'
                                                        id='importeSinBoleta'
                                                        onChange={handleChange}
                                                        value={modalForm.importeSinBoleta}
                                                        min={0}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label size='sm'>Intereses</Label>
                                                    <Input
                                                        size="sm"
                                                        type='number'
                                                        name='interesesSinBoleta'
                                                        id='interesesSinBoleta'
                                                        onChange={handleChange}
                                                        value={modalForm.interesesSinBoleta}
                                                        min={0}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label size="sm">Total</Label>
                                                    <Input
                                                        size="sm"
                                                        type="number"
                                                        name="monto"
                                                        value={modalForm.monto}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                {[1, 2, 3].includes(parseInt(modalForm?.idFrente)) && <Col>
                                                    <Label size='sm'>Emp. aportantes</Label>
                                                    <Input
                                                        size="sm"
                                                        type='number'
                                                        name='cantidadPersonas'
                                                        id='cantidadPersonas'
                                                        onChange={handleChange}
                                                        value={modalForm.cantidadPersonas}
                                                        min={1}
                                                    />
                                                </Col>
                                                }
                                                {[1, 2, 3].includes(parseInt(modalForm?.idFrente)) && <Col>
                                                    <Label size='sm'>Total remun.</Label>
                                                    <Input
                                                        size="sm"
                                                        type='number'
                                                        name='totalRemuneraciones'
                                                        id='totalRemuneraciones'
                                                        onChange={handleChange}
                                                        value={modalForm.totalRemuneraciones}
                                                        min={1}
                                                    />
                                                </Col>
                                                }
                                                {[5, 6, 7, 8, 9].includes(parseInt(modalForm?.idFrente)) &&
                                                    <Col md="1">
                                                        <Label size='sm'>Nro Cuota</Label>
                                                        <Input
                                                            size="sm"
                                                            type='number'
                                                            name='nroCuota'
                                                            min={1}
                                                            value={modalForm.nroCuota}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                }
                                                {[5, 6, 7, 8, 9].includes(parseInt(modalForm?.idFrente)) &&
                                                    <Col md="1">
                                                        <Label size='sm'>Cuotas</Label>
                                                        <Input
                                                            size="sm"
                                                            type='number'
                                                            name='cuotas'
                                                            min={1}
                                                            value={modalForm.cuotas}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                }
                                                {[5, 6, 7, 8, 9].includes(parseInt(modalForm?.idFrente)) &&
                                                    <Col md="1">
                                                        <Label size='sm'>Importe {parseInt(modalForm?.idFrente) == 8 ? "Aporte" : "Convenio"}</Label>
                                                        <Input
                                                            size="sm"
                                                            type='number'
                                                            name='importeAporteConvenio'
                                                            min={1}
                                                            value={modalForm.importeAporteConvenio}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                }

                                                <Col align="right">
                                                    <br />
                                                    <Button size="sm"
                                                        onClick={() => { imputar(modalForm, "sinBoleta") }}
                                                    >
                                                        + Imputar
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    }
                                </TabPane>

                            </TabContent>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <CustomTable size="sm">
                                <thead>
                                    {tipoImputacion == "txtAfip" ?
                                        <tr>
                                            <th>Tipo</th>
                                            <th>Frente</th>
                                            <th>Periodo</th>
                                            <th>Importe</th>
                                            <th>Intereses</th>
                                            <th>Cuota</th>
                                            <th>Importe a cancelar</th>
                                            <th>Interes a cancelar</th>
                                            <th>Acciones</th>
                                        </tr>
                                        :
                                        <tr>
                                            <th>Tipo</th>
                                            <th>Frente</th>
                                            <th>Periodo</th>
                                            <th>Importe</th>
                                            <th>Intereses</th>
                                            <th>Cant. Personas</th>
                                            <th>Total remuneraciones</th>
                                            <th>Cuota</th>
                                            <th>Total a cancelar</th>
                                            <th>Acciones</th>
                                        </tr>
                                    }
                                </thead>
                                <tbody>
                                    {boletasImputadas.length == 0 ?
                                        <tr>
                                            <td colSpan={8}>Aun no imputo boletas</td>
                                        </tr>
                                        :
                                        boletasImputadas.map((b, index) =>
                                            <tr>
                                                <td>{b.idDevengado ? "Boleta pendiente de pago" : "Boleta Nueva"}</td>
                                                <td>{frentes.find(f => f.idFrente == b.idFrente)?.frente} {(b?.esBoletaInteres == 1) ? `(Bol. Interés)` : ""} </td>
                                                <td>{moment(b.periodo, "YYYYMM").format("MM/YYYY")}</td>
                                                <td>{currency(b.importeBoleta).format()}</td>
                                                <td>{currency(b.interesesBoleta).format()}</td>
                                                {tipoImputacion != "txtAfip" && <td>{(b.cantidadPersonas) ? b.cantidadPersonas : "-"}</td>}
                                                {tipoImputacion != "txtAfip" && <td>{(b.totalRemuneraciones) ? currency(b.totalRemuneraciones).format() : "-"}</td>}
                                                <td>{b.nroCuota ? `${b.nroCuota}/${b.cantCuotas}` : "-"}</td>
                                                {tipoImputacion != "txtAfip" && <td>{currency(b.monto).format()}</td>}
                                                {tipoImputacion == "txtAfip" && <td>{currency(b.afip_capital).format()}</td>}
                                                {tipoImputacion == "txtAfip" && <td>{currency(b.afip_interes).format()}</td>}
                                                <td>
                                                    <Button
                                                        size="sm"
                                                        onClick={() => {
                                                            setBoletasImputadas([
                                                                ...boletasImputadas.filter((el, i) =>
                                                                    index !== i
                                                                )])
                                                        }}
                                                    >
                                                        {b.idDevengado ? "Desimputar" : "Eliminar"}
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                                {(boletasImputadas.length > 0 && tipoImputacion != "txtAfip") &&
                                    <tfoot>
                                        <tr>
                                            <td colspan={9}>Total a pagar:</td>
                                            <td>{currency(boletasImputadas.reduce((a, b) => currency(a).add(b.monto).add(b.interesesSinBoleta).value, 0)).format()}</td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                }
                            </CustomTable>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>


            <ModalFooter>
                <Container>
                    <Row>
                        <Col>
                            <Button color="danger" onClick={() => { callback() }}>Cancelar</Button>
                        </Col>
                        <Col align="right">
                            <Button color="primary" onClick={() => { setModalConfirmacion(true) }}>Confirmar imputacion</Button>
                        </Col>
                    </Row>
                </Container>
            </ModalFooter>
        </Modal>

        <Modal isOpen={modalConfirmacion}>
            <ModalHeader>Confirmacion</ModalHeader>
            <ModalBody>
                Esta seguro que desea realizar la imputacion?
            </ModalBody>
            <ModalFooter>
                <Container fluid>
                    <Row>
                        <Col>
                            <Button color="danger" onClick={() => { setModalConfirmacion(false) }}>Cancelar</Button>
                        </Col>
                        <Col align="right">
                            <Button onClick={() => { confirmarImputacion() }}>Confirmar</Button>
                        </Col>
                    </Row>
                </Container>
            </ModalFooter>
        </Modal>

        <ModalMensaje {...modalMensaje} callback={() => { setModalMensaje({ ...modalMensaje, isOpen: false, mensaje: "" }) }} />
    </div >

}