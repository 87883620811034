import React, { useEffect, useState, createContext } from 'react';
import { Layout, Spinner } from './Components';
import { Routes, Route, useLocation, Outlet, useNavigate, redirect, Navigate } from 'react-router-dom'
import './App.scss';
import { routes } from './routes.js'
import { ApiComunicator } from './Helpers/ApiComunicator.js';
import { Header } from './Components';

export const AuthContext = createContext();

function App() {

  const [autenticado, setAutenticado] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const Login = React.lazy(() => import('./Pages/Login/Login.js'));
  const CambiarPassword = React.lazy(() => import('./Pages/Login/CambiarPassword.js'))

  const Error404 = React.lazy(() => import('./Pages/Error404.js'))

  const location = useLocation();
  const navigate = useNavigate();


  function RutasPrivadas() {

    let arrayRutas = Object.keys(routes).map(r => { return { ...routes[r], name: r } })

    const renderRutas = (padre) => {

      let h = []
      let rts = arrayRutas.filter(r => r.parent == padre)

      for (let i = 0; i < rts.length; i++) {
        let props = { path: rts[i].path() }

        if (rts[i].component) props.element = rts[i].component

        let children = renderRutas(rts[i].name)

        if (children.length > 0) {
          h.push(
            <Route {...props} >
              {rts[i].index && <Route index element={rts[i].index.component} />}
              {children}
            </Route>
          )
        }
        else {
          h.push(
            <Route {...props} >
              {rts[i].index && <Route index element={rts[i].index.component} />}
            </Route>
          )
        }
      }
      return h
    }

    return (
      <Route
        element={
          <React.Suspense fallback={<Spinner />}>
            <AuthContext.Provider value={{ setAutenticado }}>
              {autenticado ?
                location.pathname == "/empadronamiento" ? <><Header /><Outlet /></> : <Layout />
                :
                <Outlet />
              }
            </AuthContext.Provider>
          </React.Suspense>
        }
      >
        {renderRutas(null)}
      </Route>
    )
  }

  async function verificarToken() {
    try {
      await ApiComunicator('/auth/checkToken', "GET");
      setAutenticado(true);
      return;
    }
    catch (e) {

      //Si el checkToken da 401, borro todo el localStorage
      localStorage.clear();
      setAutenticado(false);
      
      if(location.pathname.startsWith("/new-password/")) {
        const pathTokens = location.pathname.split('/');
        const token = pathTokens[pathTokens.length - 2];
        //Si estoy en otra parte que no sea el login, redirijo al login
        if (location.pathname !== "/login" && location.pathname !== "/recuperar" && !location.pathname.startsWith("/new-password/") && !token) {
          navigate("/login", { replace: true });
        }
      } else {
        if(location.pathname !== "/login" && location.pathname !== "/recuperar") {
          navigate("/login", {replace: true});
        }
      }
      
      return;
    }
  }

  //Cada vez que cambia la ruta de la app, verifico el token
  useEffect(() => {
    if (location.pathname === "/login" && autenticado) {
      navigate(-1);
    }

    verificarToken();
  }, [location])

  return (
    <div className="App">
      <Routes>
        <Route path="login" element={
          <React.Suspense fallback={<Spinner />}>
            <AuthContext.Provider value={{ setAutenticado }}>
              <Login />
            </AuthContext.Provider>
          </React.Suspense>} />
          
          <Route path="recuperar" element={
          <React.Suspense fallback={<Spinner />}>
              <CambiarPassword tipoCambio={2} />
          </React.Suspense>} />

          <Route path="new-password/:token/:tipoCambio" element={
          <React.Suspense fallback={<Spinner />}>
              <CambiarPassword tipoCambio={3} />
          </React.Suspense>} />

        {RutasPrivadas()}

        <Route path="*" element={<Error404 />} />
      </Routes>
    </div >
  );
}

export default App;
