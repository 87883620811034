import React from 'react';
import { Navigate } from 'react-router-dom'
import { FaRegEdit, FaBook, FaRegCreditCard, FaListOl, FaHouseUser, FaUserCog, FaReceipt, FaInfoCircle, FaCog } from 'react-icons/fa';

const Home = React.lazy(() => import('./Pages/Home/Home.jsx'));
const Empadronamiento = React.lazy(() => import('./Pages/Login/Empadronamiento.js'))
const DeclaracionesJuradas = React.lazy(() => import('./Pages/DeclaracionesJuradas/DeclaracionesJuradas.js'));
const Convenios = React.lazy(() => import('./Pages/Convenios/Convenios.jsx'));
const ConveniosGenerados = React.lazy(() => import('./Pages/Convenios/ConveniosGenerados.jsx'));
const VistaPeriodo = React.lazy(() => import('./Pages/DeclaracionesJuradas/VistaPeriodo.js'))
const VistaDeclaracion = React.lazy(() => import('./Pages/DeclaracionesJuradas/VistaDeclaracion.js'))
const CargarDeclaracion = React.lazy(() => import('./Pages/DeclaracionesJuradas/CargarDeclaracion.js'))
const Pagos = React.lazy(() => import('./Pages/Pagos/Pagos.js'))
const InformarPago = React.lazy(() => import('./Pages/Pagos/InformarPago.js'))
const PagosSinBoletas = React.lazy(() => import('./Pages/Pagos/PagosSinBoletas.js'))
const EmitirBoleta = React.lazy(() => import('./Pages/DeclaracionesJuradas/EmitirBoleta.js'))
const PagosInformados = React.lazy(() => import('./Pages/Pagos/PagosInformados.js'))
const Rectificar = React.lazy(() => import('./Pages/DeclaracionesJuradas/Rectificar.js'))
const RectificarExcel = React.lazy(() => import('./Pages/DeclaracionesJuradas/RectificarExcel.js'))
const GenerarConvenio = React.lazy(() => import('./Pages/Convenios/GenerarConvenio.js'));
const Administracion = React.lazy(() => import('./Pages/Administracion/Administracion.js'));
const Reportes = React.lazy(() => import('./Pages/Reportes/Reportes.js'));
const Boletas = React.lazy(() => import('./Pages/Boletas/Boletas.js'));
const ListadoBoletas = React.lazy(() => import('./Pages/Boletas/ListadoBoletas.js'));
const Imputacion = React.lazy(() => import('./Pages/Boletas/Imputacion.js'))
const ControlArchivos = React.lazy(() => import('./Pages/Boletas/ControlArchivos.js'))
const Importacion = React.lazy(() => import('./Pages/Boletas/Importacion.js'))
const AportesExtraordinarios = React.lazy(() => import('./Pages/AportesExtraordinarios/AportesExtraordinarios.js'))
const GenerarAporte = React.lazy(() => import('./Pages/AportesExtraordinarios/GenerarAporte.js'))
const AportesGenerados = React.lazy(() => import('./Pages/AportesExtraordinarios/AportesGenerados.js'))
const Parametros = React.lazy(() => import('./Pages/Administracion/Parametros.jsx'))
const ListadoVencimientos = React.lazy(() => import('./Pages/Administracion/ListadoVencimientos.jsx'))
const CuentaCorriente = React.lazy(() => import('./Pages/CuentaCorriente/CuentaCorriente.jsx'))
const Ayuda = React.lazy(() => import('./Pages/Ayuda/ayuda.js'))
const BoletaManual = React.lazy(() => import('./Pages/Boletas/BoletaManual.js'))
const CambiarPasswordMenu = React.lazy(() => import('./Pages/Ayuda/cambiarPasswordMenu.js'))
const ReporteCondonaciones = React.lazy(() => import('./Pages/Reportes/ReporteCondonaciones.jsx'))
const ReporteEmpresasDeudoras = React.lazy(() => import('./Pages/Reportes/ReporteEmpresasDeudoras.jsx'))
const ReporteEmpresas = React.lazy(() => import('./Pages/Reportes/ReporteEmpresas.jsx'))
const ReporteNominasRectificadas = React.lazy(() => import('./Pages/Reportes/ReporteNominasRectificadas.jsx'))
const ReporteApiRecaudacion = React.lazy(() => import('./Pages/Reportes/ReporteApiRecaudacion.jsx'))
const CancelarSaldosAFavor = React.lazy(() => import('./Pages/Boletas/CancelarSaldosAFavor.js'))
const Logs = React.lazy(() => import('./Pages/Administracion/Logs.jsx'))
const ListadoEmpleadosExcel = React.lazy(() => import('./Pages/Reportes/ListadoEmpleados.jsx'))
const Indicadores = React.lazy(() => import('./Pages/Reportes/Indicadores.jsx'))
const ReporteExtractos = React.lazy(() => import('./Pages/Reportes/ReporteExtracto.jsx'))
const ReporteImputaciones = React.lazy(() => import('./Pages/Reportes/ReporteImputaciones.jsx'))
const BoletaLibre = React.lazy(() => import('./Pages/DeclaracionesJuradas/BoletaLibre.js'))

export const routes = {

    //HOME PATH
    default: {
        path: _ => "",
        component: <Navigate to="home" replace />,
        parent: null,
        pathPattern: "/"
    },

    home: {
        path: _ => "home",
        component: <Home />,
        parent: null,
        icon: <FaHouseUser />,
        name: "Inicio",
        pathPattern: "/home"
    },


    //DDJJ
    ddjj: {
        path: _ => "ddjj",
        component: null,
        parent: null,
        icon: <FaRegEdit />,
        index: {
            component: <DeclaracionesJuradas />
        },
        name: "Declaraciones Juradas",
        pathPattern: "/ddjj"
    },
    vistaPeriodo: {
        path: periodo => periodo ? `periodo/${periodo}` : `periodo/:periodo`,
        component: null,
        parent: "ddjj",
        index: {
            component: <VistaPeriodo />
        },
        pathPattern: "/ddjj/periodo/:periodo"
    },
    declaracionJurada: {
        path: idDeclaracionJurada => idDeclaracionJurada ? `declaracion/${idDeclaracionJurada}` : `declaracion/:idDeclaracionJurada`,
        component: <VistaDeclaracion />,
        parent: "vistaPeriodo",
        pathPattern: "/ddjj/periodo/:periodo/declaracion/:idDeclaracionJurada"
    },
    cargarDeclaracion: {
        path: _ => "cargar-declaracion",
        component: <CargarDeclaracion />,
        parent: "vistaPeriodo",
        pathPattern: "/ddjj/periodo/:periodo/cargar-declaracion"
    },
    emitirBoleta: {
        path: tipo => tipo ? `emitir-boleta/${tipo}` : `emitir-boleta/:tipo`,
        component: <EmitirBoleta />,
        parent: "vistaPeriodo",
        pathPattern: "/ddjj/periodo/:periodo/emitir-boleta/:tipo"
    },
    rectificar: {
        path: _ => "rectificar",
        component: <Rectificar />,
        parent: "vistaPeriodo",
        pathPattern: "/ddjj/periodo/:periodo/rectificar"
    },
    rectificarExcel: {
        path: _ => "rectificarExcel",
        component: <RectificarExcel />,
        parent: "vistaPeriodo",
        pathPattern: "/ddjj/periodo/:periodo/rectificarExcel"
    },
    boletaLibre: {
        path: idRetroactivo => idRetroactivo ? "boletaLibre/" + idRetroactivo : "boletaLibre/:idRetroactivo",
        component: <BoletaLibre />,
        parent: "vistaPeriodo",
        pathPattern: "/ddjj/periodo/:periodo/boletaLibre/:idRetroactivo"
    },


    //  CONVENIOS
    convenios: {
        path: _ => "convenios",
        component: <Convenios />,
        parent: null,
        icon: <FaListOl />,
        name: "Convenios",
        pathPattern: "/convenios"
    },
    conveniosGenerados: {
        path: _ => "convenios-generados",
        component: <ConveniosGenerados />,
        parent: "convenios",
        pathPattern: "/convenios/convenios-generados"
    },
    generarConvenio: {
        path: _ => "generar-convenio",
        component: <GenerarConvenio />,
        parent: "convenios",
        pathPattern: "/convenios/generar-convenio"
    },



    //  CUENTA CORRIENTE
    cuentaCorriente: {
        path: _ => "cuenta-corriente",
        component: <CuentaCorriente />,
        parent: null,
        icon: <FaBook />,
        name: "Cuenta Corriente",
        pathPattern: "/cuenta-corriente"
    },


    //  PAGOS
    pagos: {
        path: _ => "pagos",
        component: <Pagos />,
        parent: null,
        icon: <FaRegCreditCard />,
        name: "Pagos",
        pathPattern: "/pagos"
    },
    informarPago: {
        path: _ => "informar-pago",
        component: <InformarPago />,
        parent: "pagos",
        pathPattern: "/pagos/informar-pago"
    },
    pagosInformados: {
        path: _ => "pagos-informados",
        component: <PagosInformados />,
        parent: "pagos",
        pathPattern: "/pagos/pagos-informados"
    },
    pagosSinBoletas: {
        path: _ => "pagos-sin-boletas",
        component: <PagosSinBoletas />,
        parent: "pagos",
        pathPattern: "/pagos/pagos-sin-boletas"
    },

    //  ADMINISTRADOR
    administracion: {
        path: _ => "administracion",
        component: <Administracion />,
        parent: null,
        icon: <FaUserCog />,
        name: "Administración",
        pathPattern: "/administracion"
    },
    listadoVencimientos: {
        path: _ => "listadoVencimientos",
        component: <ListadoVencimientos />,
        parent: "administracion",
        pathPattern: "/administracion/listadoVencimientos"
    },
    parametros: {
        path: _ => "parametros",
        component: <Parametros />,
        parent: "administracion",
        pathPattern: "/administracion/parametros"
    },

    // BOLETAS (ADMINISTRADOR)
    boletas: {
        path: _ => "boletas",
        component: <Boletas />,
        parent: null,
        icon: <FaReceipt />,
        name: "Boletas",
        pathPattern: "/boletas"

    },
    listadoBoletas: {
        path: _ => "listado-boletas",
        component: <ListadoBoletas />,
        parent: "boletas",
        pathPattern: "/boletas/listado-boletas"
    },
    imputacion: {
        path: _ => "imputacion",
        component: <Imputacion />,
        parent: "boletas",
        pathPattern: "/boletas/imputacion"
    },
    importacion: {
        path: _ => "importacion",
        component: <Importacion />,
        parent: "boletas",
        pathPattern: "/boletas/importacion"
    },
    boletaManual: {
        path: _ => "boleta-manual",
        component: <BoletaManual />,
        parent: "boletas",
        pathPattern: "/boletas/boleta-manual"
    },
    cancelarSaldosAFavor: {
        path: _ => "cancelar-saldos-a-favor",
        component: <CancelarSaldosAFavor />,
        parent: "boletas",
        pathPattern: "/boletas/cancelar-saldos-a-favor"
    },
    controlArchivos: {
        path: _ => "control-archivos",
        component: <ControlArchivos />,
        parent: "boletas",
        pathPattern: "/boletas/control-archivos"
    },

    // GRATIFICACIONES - CONTRIBUCIONES (ADMINISTRADOR)
    gratificaciones: {
        path: _ => "gratificaciones-contribuciones",
        component: <AportesExtraordinarios />,
        parent: null,
        icon: <FaUserCog />,
        name: "Gratificaciones",
        pathPattern: "/gratificaciones-contribuciones",
        index: { component: <Navigate to="generar" replace /> }
    },

    generarAporte: {
        path: _ => "generar",
        component: <GenerarAporte />,
        parent: "gratificaciones",
        pathPattern: "/gratificaciones-contribuciones/generar"
    },

    aportesGenerados: {
        path: _ => "aportes-generados",
        component: <AportesGenerados />,
        parent: "gratificaciones",
        pathPattern: "/gratificaciones-contribuciones/aportes-generados"
    },

    // REPORTES (ADMINISTRADOR)
    reportes: {
        path: _ => "reportes",
        component: <Reportes />,
        parent: null,
        icon: <FaUserCog />,
        /* index: {
            component: <Navigate to="informar-pago" replace />
        }, */
        name: "Reportes",
        pathPattern: "/reportes"

    },
    reporteNominasRectificadas: {
        path: _ => "reporteNominasRectificadas",
        component: <ReporteNominasRectificadas />,
        parent: "reportes",
        pathPattern: "/reportes/reporteNominasRectificadas"
    },
    reporteCondonaciones: {
        path: _ => "reporteCondonaciones",
        component: <ReporteCondonaciones />,
        parent: "reportes",
        pathPattern: "/reportes/reporteCondonaciones"
    },
    reporteEmpresas: {
        path: _ => "reporteEmpresas",
        component: <ReporteEmpresas />,
        parent: "reportes",
        pathPattern: "/reportes/reporteEmpresas"
    },
    reporteEmpresasDeudoraas: {
        path: _ => "reporteEmpresasDeudoras",
        component: <ReporteEmpresasDeudoras />,
        parent: "reportes",
        pathPattern: "/reportes/reporteEmpresasDeudoras"
    },
    listadoEmpleadosExcel: {
        path: _ => "listadoEmpleadosExcel",
        component: <ListadoEmpleadosExcel />,
        parent: "reportes",
        pathPattern: "/reportes/listadoEmpleadosExcel"
    },
    indicadores: {
        path: _ => "indicadores",
        component: <Indicadores />,
        parent: "reportes",
        pathPattern: "/reportes/indicadores"
    },
    reporteExtractos: {
        path: _ => "reporteExtractos",
        component: <ReporteExtractos />,
        parent: "reportes",
        pathPattern: "/reportes/reporteExtractos"
    },
    reporteImputaciones: {
        path: _ => "reporteImputaciones",
        component: <ReporteImputaciones />,
        parent: "reportes",
        pathPattern: "/reportes/reporteImputaciones"
    },
    reporteApiRecaudacion: {
        path: _ => "reporteApiRecaudacion",
        component: <ReporteApiRecaudacion />,
        parent: "reportes",
        pathPattern: "/reportes/reporteApiRecaudacion"
    },
    logs: {
        path: _ => "logs",
        component: <Logs />,
        parent: null,
        icon: <FaCog />,
        /* index: {
            component: <Navigate to="informar-pago" replace />
        }, */
        name: "Logs",
        pathPattern: "/logs"

    },

    //EMPADRONAMIENTO
    empadronamiento: {
        path: _ => "empadronamiento",
        component: <Empadronamiento />,
        parent: null,
        icon: null,
        name: null,
        pathPattern: "/empadronamiento"
    },

    //AYUDA
    ayuda: {
        path: _ => "ayuda",
        component: <Ayuda />,
        parent: null,
        icon: <FaInfoCircle />,
        name: "Ayuda",
        pathPattern: "/ayuda"
    },
    cambiarPasswordMenu: {
        path: _ => "cambiarPasswordMenu",
        component: <CambiarPasswordMenu />,
        parent: null,
        pathPattern: "/cambiarPasswordMenu"
    }

}
