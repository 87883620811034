import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import React from "react";

export const ModalMensaje = ({ isOpen, titulo = "atencion", mensaje, size = "md", callback }) => {

    return <div>
        <Modal
            size={size}
            isOpen={isOpen}
        >
            <ModalHeader>
                {titulo}
            </ModalHeader>
            <ModalBody>
                {mensaje}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Button
                    color="primary"
                    onClick={callback}
                >
                    Aceptar
                </Button>
            </ModalFooter>
        </Modal>
    </div >

}