import currency from "currency.js"
import { Input } from "reactstrap"

/**
 * Input que formatea el valor ingresado al sacar el foco
 */
export const InputPlata = (props, children) => {
    function handleBlur(event) {
        let e = { target: { id: event.target.id, value: currency(event?.target.value, { decimal: ",", separator: "." }).format({ decimal: ",", separator: ".", symbol: "" }) } }
        props.onChange(e)
    }
    return (
        <Input
            {...props}
            onBlur={handleBlur}
        >
            {children}
        </Input>
    )
}