import './Titulo.css';

export const Titulo = props => {

    switch (props.size) {
        case "sm":
            return <div className="titulo">
                <h6>{props.children}</h6>
                <div className="otro" />
            </div>
        default:
            return <div className="titulo">
                <h4><b>{props.children}</b></h4>
                <div className="separador" />
            </div>

    }

}