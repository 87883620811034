import { Nav, NavItem, NavLink } from 'reactstrap';
import './Header.css';
import logo from '../../assets/uom-logo.svg';
import { PanelUsuario } from './PanelUsuario';
import { SelectSucursales } from './SelectSucursal';
import { SelectFiliales } from './SelectFilial';

export const Header = ({ }) => {

    return (
        <Nav id="app-header">
            <NavItem>
                <NavLink>
                    <img id="logo-uom" src={logo} alt="UOM" /> <span id="titulo-uom">Unión Obrera Metalúrgica</span>
                </NavLink>
            </NavItem>
            <NavItem>
                {localStorage?.codigoPerfil === "P_EMP" &&
                    <SelectSucursales />
                }
                {localStorage?.codigoPerfil === "P_SECC" &&
                    <SelectFiliales />
                }
                <PanelUsuario />
            </NavItem>
        </Nav>
    )
}