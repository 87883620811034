import { Container, Navbar } from "reactstrap"
import React, { useEffect, useState } from "react";


export const InfoFooter = ({
    isOpen = true,
    backgroundColor = "gainsboro",
    children
}) => {

    if (!isOpen) return

    return < div className="fixed-bottom" >
        <Navbar style={{ backgroundColor: backgroundColor }}>
            {children}
        </Navbar>
    </div >


}