import { Input, FormGroup, Label } from 'reactstrap';
import './Header.css';
import { ApiComunicator } from "../../Helpers/ApiComunicator";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../Spinner/Spinner';

export const SelectSucursales = () => {
    const [sucursales, setSucursales] = useState()
    const [idSucursal, setIdSucursal] = useState(localStorage?.getItem('idSucursal'))
    const [loading, setLoading] = useState(false)
    const [perfiles, setPerfiles] = useState(null)

    const navigate = useNavigate()

    async function traerSucursales() {
        try {
            const data = await ApiComunicator(`/sucursales/getSucursalesByUsuarioPerfil`, "GET")
            setSucursales(data)
        }
        catch (error) {
            // throw error
        }
    }

    async function handleChange(e) {
        e.preventDefault();
        try {
            // setIdSucursal(e.target.value)
            setIdSucursal(e.target.value)
            setLoading(true)
            const { data } = await ApiComunicator(`/auth/changeToken`, "POST", { ...localStorage, idSucursal: parseInt(e.target.value) })
            //Limpio el Local Storage
            localStorage.clear();
            //Guardo los datos básicos del usuario en el Local Storage
            Object.entries(data).forEach(([key, value]) => {
                localStorage.setItem(key, value);
            });
            localStorage.setItem('razonSocial', sucursales.find(suc => suc.idSucursal == e.target.value).razonSocial);
            localStorage.setItem('cuit', sucursales.find(suc => suc.idSucursal == e.target.value).cuit);
            localStorage.setItem('idEstadoSucursal', sucursales.find(suc => suc.idSucursal == e.target.value).idEstado);
            if(sucursales.find(suc => suc.idSucursal == e.target.value).forzarEmpadronamiento == 1) {
                navigate("/empadronamiento", {replace: true})
            } else {
                navigate("/home", { replace: true })
            }
        }
        catch (error) {
            /* throw error */
        }
        finally {
            setLoading(false)
        }
    }

    const getPerfilUsuario = async () => {
        await ApiComunicator(`/permisos/getPerfilesDelUsuario`, 'GET')
            .then((r) => {
                setPerfiles(r)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getPerfilUsuario()
        traerSucursales();
    }, [])

    return (
        <FormGroup id="sucursal-header">
            {loading && <Spinner />}
            {perfiles && perfiles[0].codigoPerfil !== "P_ADMIN" &&
            <>
            <span id="sucursal-header-label"><b>Seccional: </b></span>
            <Label id="sucursal-header-label-mobile"><b>Seccional</b></Label>
            <Input
                size="sm"
                type="select"
                id="idSucursal"
                onChange={handleChange}
                value={idSucursal}
                required
            >
                <option disabled value="">Seleccione una seccional</option>
                {sucursales?.length > 0 && sucursales?.sort((a, b) => {
                if ( a.sucursal < b.sucursal && a.filial ){
                  return -1;
                }
                if ( a.sucursal > b.sucursal && a.filial ){
                  return 1;
                }
                return 0;
              }).map(n => <option key={n.idSucursal} value={n.idSucursal} style={(n.estadoEmpresa == 'Baja' || n.estadoEmpresa == 'Baja por sucesora') ? {color: 'red'} : {}}>{(n.estadoEmpresa == 'Baja' || n.estadoEmpresa == 'Baja por sucesora') ? `${n.estadoEmpresa.toUpperCase()}: ` : ""} N° {n.numeroEmpresa} {n.sucursal} ({n.filial})</option>)}
            </Input></>}
        </FormGroup>
    )
}