import { Table } from 'reactstrap';
import './CustomTable.css';

export const CustomTable = props => {

    return (
        <Table {...props} className="custom-table">
            {props.children}
        </Table>
    )
}